<template>
  <a-alert v-if="!isShow" message="Notice" type="warning" show-icon>
    <template #description>
      <a-row type="flex" justify="space-between">
        <p class="mb-sm-0">
          Please record invoice number for billing purposes.
        </p>
        <a-typography-title :level="4" style="margin-bottom: 0" copyable>
          {{ reportNo }}
        </a-typography-title>
      </a-row>
    </template>
  </a-alert>

  <a-divider orientation="left"> Basic Information </a-divider>
  <a-descriptions
    size="small"
    bordered
    :labelStyle="{ color: '#666666' }"
    :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
    ><!--:labelStyle="{ fontWeight: 500, color:'#666666' }"-->
    <a-descriptions-item label="Date:">
      {{ model.date ? $customDate.mdy(model.date) : "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item :span="2" label="Facility:">
      {{ hospital.name }}
    </a-descriptions-item>
    <template v-if="!isDisposableOnlyCase">
      <a-descriptions-item :label="`${$comman.mrNumber(hospital)}:`">
        {{ model.mr_number ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Account Number:">
        {{ model.account_number ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item v-if="!$comman.isCanReceiveOrgansHospital(hospital)">
        <template #label> Patient First Name: </template>
        {{ model.patient_first_name ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item v-if="!$comman.isCanReceiveOrgansHospital(hospital)">
        <template #label> Patient Last Name: </template>
        {{ model.patient_last_name ?? "N/A" }}
      </a-descriptions-item>
      <template v-if="$comman.isCanReceiveOrgansHospital(hospital)">
        <a-descriptions-item label="Donor Hospital:">
          {{ model.donor_hospital ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Organ(s) Considered:">
          {{
            model.organs_considered ? model.organs_considered.join(", ") : "N/A"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Organ(s) Recovered:">
          {{
            model.organs_recovered ? model.organs_recovered.join(", ") : "N/A"
          }}
        </a-descriptions-item>
      </template>
      <a-descriptions-item label="Allergies:">
        {{ model.allergies ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Height (cm):">
        {{ model.height ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Weight (kg):">
        {{ model.weight ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item>
        <template #label> Body Surface (m<sup>2</sup>): </template>
        {{ model.body_surface_area ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Blood Type:">
        {{ model.blood_type ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Date of Birth:">
        {{ model.dob ? $customDate.mdy(model.dob) : "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Gender:">
        {{ model.gender ?? "N/A" }}
      </a-descriptions-item>
      <template v-if="$comman.isCanReceiveOrgansHospital(hospital)">
        <a-descriptions-item>
          <template #label> Age (In Years): </template>
          {{ model.age ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item>
          <template #label> Accepting Center: </template>
          {{ model.accepting_center ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item>
          <template #label> Additional Accepting Center: </template>
          {{ model.additional_accepting_center ?? "N/A" }}
        </a-descriptions-item>
      </template>
    </template>
    <a-descriptions-item
      v-if="model.is_disposable_only_case"
      label="PO Number:"
    >
      {{ model.po_number ?? "N/A" }}
    </a-descriptions-item>
  </a-descriptions>

  <a-divider orientation="left"> Operative Data </a-divider>
  <template v-if="!isDisposableOnlyCase">
    <a-table
      :columns="personnelColumns"
      :rowKey="(record, index) => index"
      :dataSource="model.personnel"
      :pagination="false"
      size="small"
      class="ant-table-vertical-align-baseline"
      :scroll="{ x: 768, y: 0 }"
    >
      <template #title>
        <a-typography-title :level="5">Personnel</a-typography-title>
      </template>
    </a-table>
  </template>
  <a-descriptions
    size="small"
    bordered
    :labelStyle="{ color: '#666666' }"
    :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
  >
    <template v-if="!isDisposableOnlyCase">
      <a-descriptions-item
        v-if="model.service_category != 1"
        label="Procedure:"
      >
        {{ model.procedure ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item
        v-if="!$comman.isCanReceiveOrgansHospital(hospital)"
        label="Status:"
        :span="model.service_category != 1 ? 1 : 2"
      >
        {{
          $comman.getValueFromObject(
            model,
            "case_status",
            $constants.caseStatuses
          )
        }}
      </a-descriptions-item>
      <template v-if="model.case_status == 2 && model.emergent_request_date">
        <a-descriptions-item label="Emergent Request Date & time:">
          {{ $customDate.mdy(model.emergent_request_date) }}
          {{ model.emergent_request_time ?? "N/A" }}
        </a-descriptions-item>

        <a-descriptions-item label="Arrival Date & time into Facility:">
          {{ $customDate.mdy(model.arrival_date_into_facility) }}
          {{ model.arrival_time_into_facility ?? "N/A" }}
        </a-descriptions-item>

        <a-descriptions-item
          label="Emergent Response Time (Minutes):"
          :span="2"
        >
          {{ model.emergent_response_time ?? "N/A" }}
        </a-descriptions-item>
      </template>
    </template>
    <a-descriptions-item v-if="!isAdmin" :span="2" label="Services:">
      <template
        v-for="caseServiceName in caseServicesName"
        :key="caseServiceName.value"
      >
        <a-tag>{{ caseServiceName.text }}</a-tag>
      </template>
    </a-descriptions-item>

    <template
      v-if="
        $comman.nrpServiceSelectedWithOrganHospital(model.services, hospital)
      "
    >
      <a-descriptions-item label="Organs Targeted for Recovery:">
        {{ model.organs_targeted_for_recovery ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Cannulation Strategy:">
        {{ model.cannulation_stratege ?? "N/A" }}
      </a-descriptions-item>
    </template>

    <template v-if="!isDisposableOnlyCase">
      <a-descriptions-item :span="2" label="Service Category:">
        {{
          $comman.getValueFromObject(
            model,
            "service_category",
            $constants.serviceCategories
          )
        }}
      </a-descriptions-item>
    </template>
  </a-descriptions>
  <template v-if="!isDisposableOnlyCase">
    <a-descriptions
      size="small"
      bordered
      :labelStyle="{ color: '#666666' }"
      :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
      v-if="isCatiInvoiceAmountShow"
    >
      <a-descriptions-item label="Carolina Autotransfusion Invoice Amount:">
        {{ $comman.withCurrency(model.cati_invoice_amount) }}
      </a-descriptions-item>
      <a-descriptions-item label="Purchase Order Number:">
        {{ model.purchase_order_number }}
      </a-descriptions-item>
      <template
        v-if="
          'additional_time_charge' in model &&
          hospital.is_additional_time_charges_enable
        "
      >
        <a-descriptions-item label="Additional Time Charge:">
          {{
            model.additional_time_charge
              ? $comman.withCurrency(model.additional_time_charge)
              : "N/A"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Total Amount:">
          {{
            $comman.withCurrency(
              model.cati_invoice_amount + (model.additional_time_charge ?? 0)
            )
          }}
        </a-descriptions-item>
      </template>
    </a-descriptions>
  </template>

  <a-table
    v-if="isAdmin"
    :columns="serviceColumns"
    :rowKey="(record, index) => index"
    :dataSource="caseServiceTableForAdmin"
    :pagination="false"
    size="small"
    class="ant-table-vertical-align-baseline"
    :scroll="{ x: 768, y: 0 }"
  >
  </a-table>

  <template
    v-if="
      model.services &&
      $constants.disposableProductsStep.reduce(
        (bool, curr) => bool || model.services.includes(curr),
        false
      )
    "
  >
    <a-divider orientation="left"> Disposable Products </a-divider>
    <a-table
      :columns="productColumns"
      :rowKey="(record, index) => index"
      :dataSource="record.disposable_products"
      :pagination="false"
      size="small"
      class="ant-table-vertical-align-baseline"
      :scroll="{ x: 768, y: 0 }"
    >
    </a-table>
  </template>

  <template
    v-if="
      model.services &&
      $constants.acuteNormovolemicHemodilutionStep.reduce(
        (bool, curr) => bool || model.services.includes(curr),
        false
      )
    "
  >
    <a-divider orientation="left">
      Acute Normovolemic Hemodilution (ANH)
    </a-divider>

    <a-descriptions
      size="small"
      bordered
      :labelStyle="{ color: '#666666', width: '300px' }"
      :column="1"
    >
      <a-descriptions-item label="Blood Sequestered (mL)">
        {{ model.blood_sequestered }}
      </a-descriptions-item>
      <a-descriptions-item label="What type of anticoagulation used?">
        {{ model.anticoagulation_type }}
      </a-descriptions-item>
    </a-descriptions>
  </template>

  <template
    v-if="
      model.services &&
      $constants.qualityIndicatorsStep.reduce(
        (bool, curr) => bool || model.services.includes(curr),
        false
      ) &&
      !$comman.nrpServiceSelectedWithOrganHospital(model.services, hospital)
    "
  >
    <a-divider orientation="left"> Quality Indicators </a-divider>
    <a-descriptions
      size="small"
      bordered
      :labelStyle="{ color: '#666666' }"
      :column="{ xs: 1, sm: 1, md: 1, lg: 3 }"
    >
      <a-descriptions-item label="CPB Time: (Minutes)">
        {{ model.cpb_time ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Cross-Clamp Time: (Minutes)">
        {{ model.cross_clamp_time ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Circulatory Arrest Time: (Minutes)">
        {{ model.circulatory_arrest_time ?? "N/A" }}
      </a-descriptions-item>
    </a-descriptions>
    <a-descriptions
      size="small"
      bordered
      :labelStyle="{ color: '#666666' }"
      :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
    >
      <a-descriptions-item label="First Blood Glucose Level:">
        {{ model.first_blood_glucose_level ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Last Blood Glucose Level:">
        {{ model.last_blood_glucose_level ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Anesthesia Volume Pre-Bypass (mLs):">
        {{ model.anesthesia_volumn_pre_bypass ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Net Prime Volume (NPV) (mLs):">
        {{ model.net_prime_volume ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Ultrafiltration on CPB (mLs):">
        {{
          model.ultrafiltration_on_cpb ? -model.ultrafiltration_on_cpb : "N/A"
        }}
      </a-descriptions-item>

      <a-descriptions-item
        label="Modified Ultrafiltration (MUF) (mLs) - Post Bypass:"
      >
        {{ model.modified_ultrafiltration ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item
        label="Activated Clotting Time (ACT) >400 seconds prior to initiation of CPB:"
      >
        {{ model.act_seconds_prior_to_initiation ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item
        label="Activated Clotting Time (ACT) >400 seconds while on CPB:"
      >
        {{ model.act_seconds_while_on_cpb ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Lowest ACT on CPB (seconds):">
        {{ model.lowest_act_on_cpb ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Post Induction Hematocrit (%):">
        {{ model.post_induction_hematocrit ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="First CPB Hematocrit (%):">
        {{ model.first_cpb_hematocrit ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Percent Decrease in Hematocrit (%):">
        {{
          model.percent_decrease_in_hemtocrit
            ? parseFloat(model.percent_decrease_in_hemtocrit).toFixed(2)
            : "N/A"
        }}
      </a-descriptions-item>

      <a-descriptions-item label="Lowest CPB Hematocrit (%):">
        {{ model.lowest_cpb_hematocrit ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Last CPB Hematocrit (%):">
        {{ model.last_cpb_hematocrit ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Last In Room Hematocrit (%):">
        {{ model.last_in_room_hematocrit ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Lowest Arterial Pressure on CPB (mmHg):">
        {{ model.lowest_arterian_pressure_on_cpb ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Lowest SvO2 on CPB (%):">
        {{ model.lowest_svo2_on_cpb ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Lowest CPB Base Excess/Deficit (mEq/L):">
        {{ model.lowest_cpb_base_excess_deficit ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Lowest Hb:">
        {{ model.lowest_hb ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Highest Blood Glucose Level (mg/dL):">
        {{ model.highest_blood_glucose_level ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item
        label="Highest Venous Blood Temperature (degrees C):"
      >
        {{ model.highest_arterial_blood_temperature ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Lowest Core Body Temperature (degrees C):">
        {{ model.lowest_core_body_temperature ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Minimum Cardiac Index (CI) During CPB:">
        {{ model.minimum_cardiac_index_during_cpb ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item
        label="Packed Red Blood Cell (PRBC) Transfusion during CPB:"
      >
        {{ model.prbc_transfusion_during_cpb ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Additional Crystalloid Volume during CPB:">
        {{ model.additional_crystalloid_volume_during_cpb ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Estimated Blood Volume (mL):">
        {{ model.estimated_blood_volume ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Predicted CPB Hematocrit (%):">
        {{ model.predicted_cpb_hematocrit ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item
        :span="2"
        label="Difference (%) in Predicted cpb hct vs Actual CPB HCT:"
      >
        {{ model.diff_predicted_cpb_hct_vs_actual_cpb_hct ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="PRBC:">
        {{ model.qi_prbc ?? 0 }}
      </a-descriptions-item>

      <a-descriptions-item label="FFP:">
        {{ model.qi_ffp ?? 0 }}
      </a-descriptions-item>

      <a-descriptions-item label="Platelets:">
        {{ model.qi_platelets ?? 0 }}
      </a-descriptions-item>

      <a-descriptions-item label="Cryo:">
        {{ model.qi_cryo ?? 0 }}
      </a-descriptions-item>
    </a-descriptions>
  </template>

  <template
    v-if="
      model.services &&
      $constants.cellSaverOperativeDataStep.reduce(
        (bool, curr) => bool || model.services.includes(curr),
        false
      )
    "
  >
    <a-divider orientation="left"> Cell Saver Operative Data </a-divider>
    <a-descriptions
      size="small"
      bordered
      :labelStyle="{ color: '#666666' }"
      :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
    >
      <a-descriptions-item :span="2" label="Type of Anticoagulant Used:">
        {{ model.type_of_anticoagulant_used ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Washed RBCs Reinfused (mLs):">
        {{ model.washed_rbcs_reinfused ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Total Processed Volume (mLs):">
        {{ model.total_processed_volumn ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Anticoagulation Volume Used (mLs):">
        {{ model.anticoagulant_volumn_used ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Wash Volume Used (mLs):">
        {{ model.wash_volumn_used ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Estimated Blood Loss (EBL):">
        {{ model.estimated_blood_loss ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Clear Effluent Wash Line:">
        {{ model.clear_effluent_wash_line ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Autologous Blood Transfused within 4 Hours:">
        {{ model.autologous_blood_transfused_within_4_hours ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item
        label="Cell Saver Non-Invasive HCT (LivaNova Xtra or Fresenius CATS Devices Only):"
      >
        {{ model.cell_saver_hct ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item
        label="Was a QC sample obtained for this procedure?:"
      >
        {{ model.was_a_qc_sample_obtained_for_this_procedure ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Air Removed from Bag (Intraop):">
        {{ model.air_removed_from_bag ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Maximum Vacuum <= 150 (Intraop):">
        {{ model.maximum_vaccum_less_than_150 ?? "N/A" }}
      </a-descriptions-item>
    </a-descriptions>
    <a-table
      v-if="model.was_a_qc_sample_obtained_for_this_procedure == 'Yes'"
      :columns="sourceColumns"
      :rowKey="(record, index) => index"
      :dataSource="model.sources"
      :pagination="false"
      size="small"
      class="ant-table-vertical-align-baseline"
      :scroll="{ x: 768, y: 0 }"
    >
    </a-table>
  </template>

  <template v-if="!isDisposableOnlyCase">
    <template
      v-if="
        !$comman.nrpServiceSelectedWithOrganHospital(model.services, hospital)
      "
    >
      <a-divider orientation="left"> Procedure Info </a-divider>
      <a-descriptions
        size="small"
        bordered
        :labelStyle="{ color: '#666666' }"
        :column="{ xs: 1, sm: 1, md: 1, lg: 3 }"
        v-if="model.service_category == 1"
      >
        <a-descriptions-item label="Re-Operation:" :span="3">
          {{ model.reop ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Coronary Artery Bypass:"
          :span="model.coronary_artery_bypass == 'Yes' ? 1 : 3"
        >
          {{ model.coronary_artery_bypass ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item
          v-if="model.coronary_artery_bypass == 'Yes'"
          label="CAB Technique:"
        >
          {{ model.cab_technique ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item
          v-if="model.coronary_artery_bypass == 'Yes'"
          label="Number of Grafts:"
        >
          {{ model.number_of_grafts ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        size="small"
        bordered
        :labelStyle="{ color: '#666666' }"
        :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
        v-if="model.service_category == 1"
      >
        <a-descriptions-item label="Aortic:">
          {{ model.aortic ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Mitral:">
          {{ model.mitral ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Tricuspid:">
          {{ model.tricuspid ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Pulmonic:">
          {{ model.pulmonic ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Other Cardiac Procedure:"
          :span="model.other_cardiac_procedure == 'Yes' ? 1 : 2"
        >
          {{ model.other_cardiac_procedure ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item
          v-if="model.other_cardiac_procedure == 'Yes'"
          label="Cardiac Procedure:"
        >
          {{ model.cardiac_procedure ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Other Non-Cardiac Procedure:"
          :span="model.other_non_cardiac_procedure == 'Yes' ? 1 : 2"
        >
          {{ model.other_non_cardiac_procedure ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item
          v-if="model.other_non_cardiac_procedure == 'Yes'"
          label="Non-Cardiac Procedure:"
        >
          {{ model.non_cardiac_procedure ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        size="small"
        bordered
        :labelStyle="{ color: '#666666' }"
        :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
      >
        <a-descriptions-item label="Patient In Room Time:">
          {{ model.patient_in_room_time ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Surgery Start Time:">
          {{ model.surgery_start_time ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Surgery End Time:">
          {{ model.surgery_end_time ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Patient Out Of Room Time:">
          {{ model.patient_out_of_room_time ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        v-if="hospital.is_additional_time_charges_enable"
        size="small"
        bordered
        :labelStyle="{ color: '#666666' }"
        :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
      >
        <a-descriptions-item label="Arrival Date:">
          {{
            model.cati_arrival_date
              ? $customDate.mdy(model.cati_arrival_date)
              : "N/A"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Arrival time:">
          {{ model.cati_arrival_time ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Departure Date:">
          {{
            model.cati_departure_date
              ? $customDate.mdy(model.cati_departure_date)
              : "N/A"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Departure Time:">
          {{ model.cati_departure_time ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Total Time:">
          {{ model.cati_total_time ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions size="small" bordered :labelStyle="{ color: '#666666' }">
        <a-descriptions-item label="Would you like to record STS data?">
          {{ model.is_sts_data }}
        </a-descriptions-item>
      </a-descriptions>
      <template v-if="model.is_sts_data == 'Yes'">
        <a-descriptions
          size="small"
          bordered
          :labelStyle="{ color: '#666666' }"
          :column="{ xs: 1, sm: 1, md: 1, lg: 1 }"
        >
          <a-descriptions-item label="Endoscopic Vein Harvest Time (In Mins):">
            {{ model.endoscopic_vein_harvest_time ?? "N/A" }}
          </a-descriptions-item>

          <a-descriptions-item label="Radial Artery Harvest Time (In Mins):">
            {{ model.radial_artery_harvest_time ?? "N/A" }}
          </a-descriptions-item>

          <a-descriptions-item label="Proximal Technique:">
            <template v-for="value in model.proximal_techniques" :key="value"
              ><a-tag>{{ value }}</a-tag></template
            >
          </a-descriptions-item>

          <a-descriptions-item label="Cardioplegia Type:">
            <template v-for="value in model.cardioplegia_types" :key="value"
              ><a-tag>{{ value }}</a-tag></template
            >
          </a-descriptions-item>
        </a-descriptions>
        <a-table
          :columns="coronaryBypassColumns"
          :rowKey="(record, index) => index"
          :dataSource="model.coronary_bypasses"
          :pagination="false"
          size="small"
          class="ant-table-vertical-align-baseline"
          :scroll="{ x: 768, y: 0 }"
        >
        </a-table>
      </template>
    </template>

    <template v-else>
      <a-divider orientation="left"> NRP QI Indicators </a-divider>
      <a-descriptions
        size="small"
        bordered
        :labelStyle="{ color: '#666666' }"
        :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
        class="mb-sm-1"
      >
        <a-descriptions-item label="No circulatory cessation, Dry run case?">
          {{ model.nrp_is_dry_run_case ? "Yes" : "No" }}
        </a-descriptions-item>
        <template v-if="!model.nrp_is_dry_run_case">
          <a-descriptions-item label="NRP Total Time (Minutes):">
            {{ model.nrp_total_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="NRP Initiation Date & time:">
            {{ $customDate.mdy(model.nrp_initiation_date) }}
            {{ model.nrp_initiation_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="NRP End Date & time:">
            {{ $customDate.mdy(model.nrp_end_date) }}
            {{ model.nrp_end_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item
            label="Withdrawal of Life Sustaining Therapy Date & time:"
          >
            {{
              $customDate.mdy(
                model.nrp_withdrawal_of_life_sustaining_therapy_date
              )
            }}
            {{ model.nrp_withdrawal_of_life_sustaining_therapy_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Circulatory Cessation Date & time:">
            {{ $customDate.mdy(model.nrp_circulatory_cessation_date) }}
            {{ model.nrp_circulatory_cessation_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Declaration of Death Date & time:">
            {{ $customDate.mdy(model.nrp_declaration_of_death_date) }}
            {{ model.nrp_declaration_of_death_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="End of Observation Period Date & time:">
            {{ $customDate.mdy(model.nrp_end_of_observation_period_date) }}
            {{ model.nrp_end_of_observation_period_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Incision Date & time:">
            {{ $customDate.mdy(model.nrp_incision_date) }}
            {{ model.nrp_incision_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Heart Flush Date & time:">
            {{ $customDate.mdy(model.nrp_heart_flush_date) }}
            {{ model.nrp_heart_flush_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Lung Flush Date & time:">
            {{ $customDate.mdy(model.nrp_lung_flush_date) }}
            {{ model.nrp_lung_flush_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Head Vessels Ligated Date & time:">
            {{ $customDate.mdy(model.nrp_head_vessels_ligated_date) }}
            {{ model.nrp_head_vessels_ligated_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="NRP Aortic Cannulation Date & time:">
            {{ $customDate.mdy(model.nrp_aortic_cannulation_date) }}
            {{ model.nrp_aortic_cannulation_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="NRP Venous Cannulation Date & time:">
            {{ $customDate.mdy(model.nrp_venous_cannulation_date) }}
            {{ model.nrp_venous_cannulation_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Aortic and IVC Clamp Date & time:">
            {{ $customDate.mdy(model.nrp_aortic_and_ivc_clamp_date) }}
            {{ model.nrp_aortic_and_ivc_clamp_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Cold Aortic Flush Post NRP Date & time:">
            {{ $customDate.mdy(model.nrp_cold_aortic_flush_post_date) }}
            {{ model.nrp_cold_aortic_flush_post_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Functional Warm Ischemic Date & time:">
            {{ $customDate.mdy(model.nrp_functional_warm_ischemic_date) }}
            {{ model.nrp_functional_warm_ischemic_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Venous Cannula Location:">
            {{ model.nrp_venous_cannula_location ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Arterial Cannula Location:">
            {{ model.nrp_arterial_cannula_location ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Venous Clamp Location:">
            {{ model.nrp_venous_clamp_location ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Aortic Clamp Location(s):">
            {{ model.nrp_aortic_clamp_locations ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item
            label="Time from Withdrawal of Care to on NRP (Minutes):"
          >
            {{ model.nrp_time_from_withdrawal_of_care ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Time from Incision to on NRP (Minutes):">
            {{ model.nrp_time_from_incision ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item
            label="Time from Functional Warm Ischemic to on NRP (Minutes):"
          >
            {{ model.nrp_time_from_functional_warm_ischemic ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item
            label="Functional Warm Ischemic Time Parameters:"
          >
            {{ model.nrp_functional_warm_ischemic_time_params ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Minimum Flow During NRP (LPM):">
            {{ model.nrp_minimum_flow ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Maximum Flow During NRP (LPM):">
            {{ model.nrp_maximum_flow ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Highest Lactate during NRP:">
            {{ model.nrp_max_lac ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Lowest Lactate during NRP:">
            {{ model.nrp_min_lac ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item
            label="Percent Change from First to Last Lactate:"
          >
            {{
              model.nrp_percentage_lac != null
                ? `${parseFloat(model.nrp_percentage_lac).toFixed(2)}%`
                : "N/A"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Starting BASE Excess during NRP:">
            {{ model.nrp_first_base ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Ending BASE Excess during NRP:">
            {{ model.nrp_last_base ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item
            label="Percent Change from Staring to Ending BASE:"
            :span="2"
          >
            {{
              model.nrp_percentage_base != null
                ? `${parseFloat(model.nrp_percentage_base).toFixed(2)}%`
                : "N/A"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Blended technology case?" :span="2">
            {{ model.nrp_blended_technology_case ? "Yes" : "No" }}
          </a-descriptions-item>
          <template v-if="model.nrp_blended_technology_case">
            <template v-for="i in [1, 2, 3]" :key="i">
              <a-descriptions-item :label="`Organ #${i}`">
                {{ model[`nrp_blended_organ_${i}`] ?? "N/A" }}
              </a-descriptions-item>
              <a-descriptions-item :label="`Method #${i}`">
                {{ model[`nrp_blended_method_${i}`] ?? "N/A" }}
                {{
                  model[`nrp_blended_method_${i}`] == "Other"
                    ? `(${model[`nrp_blended_other_method_${i}`]})`
                    : ""
                }}
              </a-descriptions-item>
            </template>
          </template>
        </template>
      </a-descriptions>

      <a-typography-text strong> Medication Administration </a-typography-text>

      <a-table
        :columns="nrpMedicinesColumn"
        :rowKey="(_, index) => `${index}_medicines`"
        :dataSource="model.nrp_medicines"
        :pagination="false"
        size="small"
        class="ant-table-vertical-align-baseline mb-sm-1"
        :scroll="{ x: true, y: 0 }"
      />

      <a-typography-text strong>
        Blood Product Administration
      </a-typography-text>

      <a-table
        :columns="nrpBloodProductsColumn"
        :rowKey="(_, index) => `${index}_blood_products`"
        :dataSource="model.nrp_blood_products"
        :pagination="false"
        size="small"
        class="ant-table-vertical-align-baseline"
        :scroll="{ x: true, y: 0 }"
      />

      <a-divider orientation="left"> NRP Procedure Info </a-divider>
      <a-descriptions
        size="small"
        bordered
        :labelStyle="{ color: '#666666' }"
        :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
        class="mb-sm-1"
      >
        <a-descriptions-item label="Scheduled OR Date & time:">
          {{ $customDate.mdy(model.nrp_2_scheduled_or_date) }}
          {{ model.nrp_2_scheduled_or_time ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="KPS Team Arrival Date & time:">
          {{ $customDate.mdy(model.nrp_2_kps_team_arrival_date) }}
          {{ model.nrp_2_kps_team_arrival_time ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="KPS Setup Complete Date & time:">
          {{ $customDate.mdy(model.nrp_2_kps_setup_complete_date) }}
          {{ model.nrp_2_kps_setup_complete_time ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="OR OPO Personnel in Room Date & time:">
          {{ $customDate.mdy(model.nrp_2_or_opo_personnel_in_room_date) }}
          {{ model.nrp_2_or_opo_Personnel_in_room_time ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Donor in Room Date & time:">
          {{ $customDate.mdy(model.nrp_2_donor_in_room_date) }}
          {{ model.nrp_2_donor_in_room_time ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="KPS Team Departure Date & time:">
          {{ $customDate.mdy(model.nrp_2_kps_team_depature_date) }}
          {{ model.nrp_2_kps_team_depature_time ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>
    </template>
  </template>

  <template
    v-if="
      model.services &&
      $constants.preservationServiceStep.reduce(
        (bool, curr) => bool || model.services.includes(curr),
        false
      )
    "
  >
    <a-divider orientation="left"> Preservation Details </a-divider>

    <a-descriptions
      size="small"
      bordered
      :labelStyle="{ color: '#666666' }"
      :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
    >
      <a-descriptions-item label="Type of Preservation Solution:">
        {{ model.ps_type_of_perservation_solution ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Organ(s) Perfused:">
        {{ model.ps_organ_perfused?.join(", ") ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Volume:">
        {{ model.ps_volumn ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Delivery Pressure:">
        {{ model.ps_delivery_pressure ?? "N/A" }}
      </a-descriptions-item>
    </a-descriptions>
  </template>

  <template
    v-if="
      model.services &&
      'pgt_na_pre_procedure_checklist' in model &&
      $constants.plateletGelTherapyStep.reduce(
        (bool, curr) => bool || model.services.includes(curr),
        false
      )
    "
  >
    <a-divider orientation="left"> Platelet Gel Therapy </a-divider>
    <a-row :gutter="[20, 0]">
      <a-col :span="12">
        <a-row type="flex">
          <a-col flex="35px"> N/A </a-col>
          <a-col flex="35px"><CheckOutlined /></a-col>
          <a-col flex="calc(100% - 70px)">
            <strong>Pre-Procedure Checklist</strong>
          </a-col>
        </a-row>

        <template
          v-for="option in $constants.pgtPreProcedureChecklist"
          :key="option"
        >
          <a-row type="flex">
            <a-col flex="35px">
              <i
                v-if="model?.pgt_na_pre_procedure_checklist[option]"
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="35px">
              <i
                v-if="model?.pgt_pre_procedure_checklist[option]"
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="calc(100% - 70px)">{{ option }}</a-col>
          </a-row>
        </template>
      </a-col>

      <a-col :span="12">
        <a-row type="flex">
          <a-col flex="35px"> N/A </a-col>
          <a-col flex="35px"><CheckOutlined /></a-col>
          <a-col flex="calc(100% - 70px)">
            <strong>Post-Procedure Checklist</strong>
          </a-col>
        </a-row>

        <template
          v-for="option in $constants.pgtPostProcedureChecklist"
          :key="option"
        >
          <a-row type="flex">
            <a-col flex="35px">
              <i
                v-if="model?.pgt_na_post_procedure_checklist[option]"
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="35px">
              <i
                v-if="model?.pgt_post_procedure_checklist[option]"
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="calc(100% - 70px)">{{ option }}</a-col>
          </a-row>
        </template>
      </a-col>

      <a-col :span="24">
        <a-descriptions
          size="small"
          bordered
          :labelStyle="{ color: '#666666' }"
          :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
        >
          <a-descriptions-item label="Volume Processed (mL):">
            {{ model.pgt_volume_processed }}
          </a-descriptions-item>
          <a-descriptions-item label="Platelet Rich Plasma (mL):">
            {{ model.pgt_platelet_rich_plasma }}
          </a-descriptions-item>
          <a-descriptions-item label="Platelet Poor Plasma (mL):">
            {{ model.pgt_platelet_poor_plasma }}
          </a-descriptions-item>
          <a-descriptions-item label="Anticoag Volume (mL):">
            {{ model.pgt_anticoag_volume }}
          </a-descriptions-item>
          <a-descriptions-item label="Volume Plasma Loss (mL):">
            {{ model.pgt_volume_plasma_loss }}
          </a-descriptions-item>
          <a-descriptions-item label="Reco Thrombin (units):">
            {{ model.pgt_reco_thrombin }}
          </a-descriptions-item>
          <a-descriptions-item>
            <template #label> CaCI<sup>2</sup> 10% (mL) </template>
            {{ model.pgt_cacl2_10 }}
          </a-descriptions-item>
          <a-descriptions-item label="Centrifuge Serial Number:">
            {{ model.pgt_centrifuge_serial_number }}
          </a-descriptions-item>
          <a-descriptions-item label="Blood Withdrawal Date & Time">
            {{ $customDate.mdy(model.pgt_blood_withdrawal_date) }}
            {{ model.pgt_blood_withdrawal_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Blood Withdrawal Site:">
            {{ model.pgt_blood_withdrawal_site ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Blood Withdrawal Performed By:">
            {{ model.pgt_blood_withdrawal_performed_by ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="PRP Product Given To:">
            {{ model.pgt_prp_product_given_to ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="PRP Product Given Date & Time:">
            {{ $customDate.mdy(model.pgt_prp_product_given_date) }}
            {{ model.pgt_prp_product_given_time ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="First PRP Application Date & Time:">
            {{ $customDate.mdy(model.pgt_first_prp_application_date) }}
            {{ model.pgt_first_prp_application_time ?? "N/A" }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
  </template>

  <template
    v-if="
      model.services &&
      $constants.qualityIndicatorsForHIPECStep.reduce(
        (bool, curr) => bool || model.services.includes(curr),
        false
      )
    "
  >
    <a-divider orientation="left"> HIPEC Quality Indicators </a-divider>
    <a-descriptions
      size="small"
      bordered
      :labelStyle="{ color: '#666666' }"
      :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
    >
      <a-descriptions-item label="Cytotoxic Drug Type & Dose:">
        {{ model.cytotoxic_drug_type_and_dose ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Prime Type and Amount (mLs):">
        {{ model.prime_type_and_amount ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Average Inflow Temperature (degrees C):">
        {{ model.average_inflow_temperature ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Average outflow Temperature (degrees C):">
        {{ model.average_outflow_temperature ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Pump Flow Rate:">
        {{ model.pump_flow_rate ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Average Reservoir Volume (mLs):">
        {{ model.average_reservoir_volume ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item :span="2" label="Additional Comments:">
        {{ model.additional_comments ?? "N/A" }}
      </a-descriptions-item>
    </a-descriptions>
  </template>

  <template
    v-if="
      model.services &&
      $constants.qualityIndicatorsForECMOStep.reduce(
        (bool, curr) => bool || model.services.includes(curr),
        false
      )
    "
  >
    <a-divider orientation="left"> ECMO Quality Indicators </a-divider>
    <a-descriptions
      size="small"
      bordered
      :labelStyle="{ color: '#666666' }"
      :column="{ xs: 1, sm: 1, md: 1, lg: 2 }"
    >
      <a-descriptions-item label="Start ECMO Run:">
        {{ $customDate.mdyhis(start_ecmo_run) }}
      </a-descriptions-item>
      <a-descriptions-item label="End ECMO Run:">
        {{ $customDate.mdyhis(end_ecmo_run) }}
      </a-descriptions-item>
      <a-descriptions-item label="Total ECMO Run Time:">
        {{ $comman.secondsToHms(model.total_ecmo_run_time * 60) }}
      </a-descriptions-item>
      <a-descriptions-item label="Lowest HCT on ECMO:">
        {{ model.lowest_hct_on_ecmo ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Lowest PTT on ECMO:">
        {{ model.lowest_ptt_on_ecmo ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Lowest Arterial BP on ECMO:">
        {{ model.lowest_arterial_bp_on_ecmo ?? "N/A" }}
      </a-descriptions-item>
    </a-descriptions>
  </template>

  <template
    v-if="
      model.services &&
      $constants.postProcedureMaintenanceStep.reduce(
        (bool, curr) => bool || model.services.includes(curr),
        false
      )
    "
  >
    <a-divider orientation="left"> Post Procedure Maintenance </a-divider>
    <a-descriptions
      size="small"
      bordered
      :labelStyle="{ color: '#666666' }"
      :column="{ sm: 1 }"
    >
      <template
        v-if="model.services.includes(10) || model.services.includes(11)"
      >
        <a-descriptions-item label="Hyperthermic pump device:">
          {{ model.hyperthermic_pump_device ?? "N/A" }}
        </a-descriptions-item>

        <a-descriptions-item
          label="Hyperthermic pump device cleaned after procedure?"
        >
          {{ model.is_hyperthermic_pump ?? "N/A" }}
        </a-descriptions-item>

        <a-descriptions-item
          v-if="
            model.is_hyperthermic_pump && model.is_hyperthermic_pump == 'No'
          "
          label="Hyperthermic pump device remarks:"
        >
          {{ model.hyperthermic_pump_remarks ?? "N/A" }}
        </a-descriptions-item>
      </template>

      <template v-else>
        <a-descriptions-item
          :label="$comman.heartLungMachineLabel(hospital, false) + ':'"
        >
          {{
            model.heart_lung_device ? model.heart_lung_device_data.name : "N/A"
          }}
          <br />
          <template
            v-for="(inv, index) in model.heart_lung_device_data?.inventory"
            :key="index"
          >
            <a-tag>
              {{ inv?.asset_inventory?.asset_product?.name }} |
              {{ inv?.asset_inventory?.model_no }} |
              {{ inv?.asset_inventory?.serial_no }}
            </a-tag>
            <br />
          </template>
        </a-descriptions-item>

        <a-descriptions-item
          :label="$comman.heartLungMachineCleanLabel(hospital)"
        >
          {{ model.is_heart_lung ?? "N/A" }}
        </a-descriptions-item>

        <a-descriptions-item
          v-if="model.is_heart_lung && model.is_heart_lung == 'No'"
          :label="$comman.heartLungMachineRemarkLabel(hospital) + ':'"
        >
          {{ model.heart_lung_remarks ?? "N/A" }}
        </a-descriptions-item>

        <template v-if="!onlyNRMServiceSelected">
          <a-descriptions-item label="Heater cooler machine device:">
            {{ model.heater_cooler_device ?? "N/A" }}
          </a-descriptions-item>

          <a-descriptions-item label="Heater cooler cleaned after procedure?">
            {{ model.is_heater_cooler ?? "N/A" }}
          </a-descriptions-item>

          <a-descriptions-item
            v-if="model.is_heater_cooler && model.is_heater_cooler == 'No'"
            label="Heater cooler machine remarks:"
          >
            {{ model.heater_cooler_remarks ?? "N/A" }}
          </a-descriptions-item>

          <a-descriptions-item label="Cell saver machine device:">
            {{ model.cell_saver_device ?? "N/A" }}
          </a-descriptions-item>

          <a-descriptions-item
            label="Cell saver device cleaned after procedure?"
          >
            {{ model.is_cell_saver ?? "N/A" }}
          </a-descriptions-item>

          <a-descriptions-item
            v-if="model.is_cell_saver && model.is_cell_saver == 'No'"
            label="Cell saver machine remarks:"
          >
            {{ model.cell_saver_remarks ?? "N/A" }}
          </a-descriptions-item>
        </template>
      </template>
    </a-descriptions>
  </template>

  <slot name="atsLiveCharting" />
  <slot name="cpbLiveCharting" />
  <slot name="hipecLiveCharting" />
  <slot name="ecmoLiveCharting" />
  <slot name="nrpLiveCharting" />

  <template v-if="!isShow">
    <!-- <a-row type="flex" justify="center" class="mt-sm-2">
      <a-space direction="vertical">
        <vue-signature-pad
          :options="{ onEnd: save }"
          ref="signaturePad"
          :customStyle="$constants.signPadCustomCss"
        />

        <a-row type="flex" justify="center">
          <a-col>
            <a-typography-paragraph>
              Employee signature indicates that the information reported is
              complete and accurate.
            </a-typography-paragraph>
          </a-col>
        </a-row>
        <a-row v-if="model.sign" justify="center">
          <a-space>
            <a-button
              htmlType="button"
              type="primary"
              danger
              ghost
              @click="clear"
            >
              Clear
            </a-button>
          </a-space>
        </a-row>
      </a-space>
    </a-row> -->
  </template>

  <template v-else>
    <template v-if="model?.additional_procedural_notes">
      <a-divider orientation="left"> Additional Procedural Notes </a-divider>
      {{ model.additional_procedural_notes }}
    </template>

    <template v-if="model?.additional_surgical_notes">
      <a-divider orientation="left"> Surgical Notes </a-divider>
      {{ model.additional_surgical_notes }}
    </template>

    <template v-if="model?.sign">
      <a-divider orientation="left"> Signature </a-divider>
      <b> {{ record.employee.full_name }} </b>
      <br />
      <img :width="200" :src="model.sign" />
    </template>

    <template v-if="model?.extra_sign?.is_added">
      <a-divider orientation="left"> Additional Signature </a-divider>
      <b>
        {{ model.extra_sign.last_name }}, {{ model.extra_sign.first_name }} (
        {{ model.extra_sign.title }})
      </b>
      <br />
      <img :width="200" :src="model.extra_sign.sign" />
    </template>

    <template v-if="model?.attachments?.length > 0">
      <a-divider orientation="left"> Attachments </a-divider>
      <template v-for="attach in model.attachments" :key="attach.id">
        <a
          href="#"
          @click.prevent="
            () => {
              $comman.caseReportLogStore({
                id: record.id,
                action: 'Attachment Download',
              });
              $comman.downloadItem(attach);
            }
          "
        >
          {{ attach.name }}
        </a>
        <br />
      </template>
    </template>
  </template>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "case-report-step-9",
  inheritAttrs: false,
  props: {
    step: String | Number,
    model: Object,
    reportNo: String,
    hospitals: Array,
    caseServices: Array,
    isCatiInvoiceAmountShow: Function | Boolean,
    isShow: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
    },
    hospital: {
      type: Object,
      default: null,
      required: false,
    },
    isDisposableOnlyCase: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["validate"],
  components: { CheckOutlined },

  data() {
    return {
      personnelColumns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
      ],
      serviceColumns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Amount",
          dataIndex: "price",
          key: "price",
          customRender: ({ text }) => this.$comman.withCurrency(text),
        },
      ],
      sourceColumns: [
        {
          title: "Source",
          dataIndex: "source",
          key: "source",
        },
        {
          title: "Parameter",
          dataIndex: "parameter",
          key: "parameter",
        },
        {
          title: "Result",
          dataIndex: "result",
          key: "result",
        },
      ],
      coronaryBypassColumns: [
        {
          title: "Graft",
          dataIndex: "graft",
          key: "graft",
        },
        {
          title: "Flow Down Graft",
          dataIndex: "flow_down_graft",
          key: "flow_down_graft",
        },
      ],
      nrpMedicinesColumn: [
        {
          title: "Date & Time",
          dataIndex: "date",
          key: "date",
          customRender: ({ record }) => `${record.date} ${record.time}`,
        },
        {
          title: "Drug",
          dataIndex: "drug",
          key: "drug",
        },
        {
          title: "Dose",
          dataIndex: "dose",
          key: "dose",
          customRender: ({ record }) => `${record.dose} ${record.doseUnit}`,
        },
        {
          title: "Administered By",
          dataIndex: "administered_by",
          key: "administered_by",
        },
        {
          title: "Lot No#",
          dataIndex: "lot_no",
          key: "lot_no",
          customRender: ({ text }) => text ?? "N/A",
        },
        {
          title: "Expiry Date",
          dataIndex: "expiry_date",
          key: "expiry_date",
          customRender: ({ text }) => text ?? "N/A",
        },
      ],

      nrpBloodProductsColumn: [
        {
          title: "Date & Time",
          dataIndex: "date",
          key: "date",
          customRender: ({ record }) => `${record.date} ${record.time}`,
        },
        {
          title: "Product Type",
          dataIndex: "product_type",
          key: "product_type",
        },
        {
          title: "Unit Type",
          dataIndex: "unit_type",
          key: "unit_type",
        },
        {
          title: "Unit ID No",
          dataIndex: "unit_id_no",
          key: "unit_id_no",
        },
        {
          title: "Unit Expiration Date",
          dataIndex: "unit_expiration_date",
          key: "unit_expiration_date",
        },
        {
          title: "Volume (mL)",
          dataIndex: "blood_product_volume",
          key: "blood_product_volume",
        },
      ],
    };
  },

  mounted() {
    //
  },

  computed: {
    ...mapGetters("auth", ["isAdmin"]),

    productColumns() {
      let res = [
        {
          title: "Product",
          dataIndex: "product_name",
          key: "product_name",
          customRender: ({ record }) =>
            record.form_data?.product_name.split(" | ")[1] ?? "N/A",
        },
        {
          title: "Lot Number",
          dataIndex: "lot_number",
          key: "lot_number",
          width: 150,
          customRender: ({ record }) => record.form_data?.lot_number ?? "N/A",
        },
        {
          title: "Expiration Date",
          dataIndex: "expiry_date",
          key: "expiry_date",
          width: 150,
          customRender: ({ record }) => record.form_data?.expiry_date ?? "N/A",
        },
        {
          title: "Stock Qty",
          dataIndex: "stock_qty",
          key: "stock_qty",
          width: 100,
          customRender: ({ record }) => record.form_data?.stock_qty ?? "N/A",
        },
        {
          title: "Used Qty",
          dataIndex: "used_qty",
          key: "used_qty",
          width: 100,
          customRender: ({ record }) => record.form_data?.used_qty ?? "N/A",
        },
      ];

      if (this.isAdmin) {
        res.push({
          title: "Amount",
          dataIndex: "price",
          key: "price",
          width: 150,
          customRender: ({ record }) => {
            let pricePerItem = record.price ?? 0;
            let qty = record.quantity ?? 0;
            return this.$comman.withCurrency(pricePerItem * qty);
          },
        });
      }

      return res;
    },

    caseServicesName() {
      return this.caseServices.filter((v) =>
        this.model.services.includes(v.value)
      );
    },

    start_ecmo_run() {
      if (moment.isMoment(this.model.start_date_of_ecmo_run)) {
        this.model.start_date_of_ecmo_run =
          this.model.start_date_of_ecmo_run.format("YYYY-MM-DD");
      }

      if (
        this.model.start_date_of_ecmo_run &&
        this.model.start_time_of_ecmo_run
      ) {
        return moment(
          this.model.start_date_of_ecmo_run +
            " " +
            this.model.start_time_of_ecmo_run,
          "YYYY-MM-DD HH:mm"
        );
      }
      return null;
    },

    end_ecmo_run() {
      if (moment.isMoment(this.model.end_date_of_ecmo_run)) {
        this.model.end_date_of_ecmo_run =
          this.model.end_date_of_ecmo_run.format("YYYY-MM-DD");
      }

      if (this.model.end_date_of_ecmo_run && this.model.end_time_of_ecmo_run) {
        return moment(
          this.model.end_date_of_ecmo_run +
            " " +
            this.model.end_time_of_ecmo_run,
          "YYYY-MM-DD HH:mm"
        );
      }
      return null;
    },

    onlyNRMServiceSelected() {
      return (
        (this.model.services.includes(19) ||
          this.model.services.includes(23)) &&
        this.model.services.length == 1
      );
    },

    caseServiceTableForAdmin() {
      let data = JSON.parse(JSON.stringify(this.record.service_request_types));
      if (
        !this.isDisposableOnlyCase &&
        this.hospital.is_additional_time_charges_enable
      ) {
        data.push({
          name: "Additional Time Charges",
          price:
            this.model.additional_time_charge &&
            this.model.additional_time_charge != null
              ? this.model.additional_time_charge
              : 0,
        });
      }
      return data;
    },
  },

  methods: {
    clear() {
      this.model.sign = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.sign = data;
      return Promise.resolve();
    },
  },
};
</script>
